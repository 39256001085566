import { useReducer, useEffect, useCallback, useRef } from "react";

import useAxios from "../../../hooks/useAxios";
import newMsgReducer from "../../../reducers/newMsgReducer";
import validateFields from "../../../helpers/formValidator";

import { AuthConsumer } from "../../../contexts/AuthProvider";

const useNewMessage = props => {
  const { initAxios } = useAxios();
  const { user } = AuthConsumer();
  const axiosGlobalController = useRef(null);
  const {
    audiences,
    fetchMessages,
    replyToRecipient,
    recipients,
    closeMessage
  } = props;

  // sort recipients alphabetically
  if (recipients && recipients.lenght > 0)
    recipients.sort((a, b) =>
      a.Name.localeCompare(b.Name, "sv", { sensitivity: "base" })
    );

  const isBackofficeUser = user.Role === "Admin" || user.Role === "Backoffice";
  const isReply = replyToRecipient ? true : false;

  const initialState = {
    loaded: false,
    isBulk: false,
    audience: "",
    recipients,
    messageThreadId: replyToRecipient?.MessageThreadId || null,
    toRecipients: isBackofficeUser
      ? replyToRecipient
        ? [
            {
              LegalEntityId: replyToRecipient.CreatorId,
              Email: replyToRecipient.SenderEmail
            }
          ]
        : []
      : [{ LegalEntityId: 0, Email: "backoffice@fairinvestments.se" }],
    subject: replyToRecipient?.Subject ? replyToRecipient.Subject : "",
    body: "",
    files: [],
    customList: [],
    submitted: false,
    sending: false,
    inputErrors: null,
    serverMessage: null,
    confirmBulkMessageModal: false
  };

  const [state, dispatch] = useReducer(newMsgReducer, initialState);

  // Updates recipient state properties after recipientSelect Autocomplete input change
  const setToRecipients = recipients => {
    return dispatch({
      type: "setToRecipients",
      payload: recipients
    });
  };

  const toggleConfirmBulkMessageModal = audience => {
    return dispatch({
      type: "toggleConfirmBulkMessageModal",
      payload: audience
    });
  };

  const toggleIsBulk = () => {
    return dispatch({
      type: "toggleIsBulk"
    });
  };

  const setAudience = audience => {
    return dispatch({
      type: "setAudience",
      payload: audience
    });
  };

  const addFiles = files => {
    return dispatch({
      type: "addFiles",
      payload: files
    });
  };

  const removeFile = file => {
    return dispatch({
      type: "removeFile",
      payload: file
    });
  };

  const clearFiles = () => {
    return dispatch({
      type: "clearFiles"
    });
  };

  const addCustomList = files => {
    return dispatch({
      type: "addCustomList",
      payload: files
    });
  };

  const removeCustomList = file => {
    return dispatch({
      type: "removeCustomList",
      payload: file
    });
  };

  const clearCustomList = () => {
    return dispatch({
      type: "clearCustomList"
    });
  };

  const handleInputChange = e => {
    e.preventDefault();

    return dispatch({
      type: "handleInput",
      field: e.target.name,
      payload: e.target.value
    });
  };

  const sendMessage = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    dispatch({
      type: "sending",
      payload: true
    });

    let { subject, body, files, customList, messageThreadId, audience, toRecipients } =
      state;

    const attachedFiles = files.map(file => {
      return {
        name: file.name,
        extension: file.name.split(".").pop(),
        size: file.size,
        type: file.type,
        data: file.rawData
      };
    });

    const customRecipients = customList.map(file => {
      return {
        name: file.name,
        extension: file.name.split(".").pop(),
        size: file.size,
        type: file.type,
        data: file.rawData
      };
    });

    try {
      const response = await axiosInstance.post("/message/send", {
        messageThreadId,
        audience,
        toRecipients,
        subject,
        body,
        attachedFiles,
        customRecipients
      });

      if (response.status !== 204 && response.status !== 200) {
        return dispatch({
          type: "handleServerError",
          payload: "Unable to send message. Please try again later."
        });
      }

      let message = isBackofficeUser ?
          "Meddelandet har skickats till kunden." :
          "Tack, ditt meddelande har skickats och vi återkommer till dig inom kort. Vid brådskande ärenden var god kontakta kundtjänst per telefon.";

      if (isBackofficeUser && (response.data && response.data.recipientsCount))
      {
        message = "Meddelandet har skickats till " + response.data.recipientsCount + " kunder.";

        if (response.data.invalidOrMissing && response.data.invalidOrMissing.length > 0)
        {
          message += " OBS! Följande rader kunde inte matchas mot kunder i databasen: " + response.data.invalidOrMissing.join(", ");
        }
      }

      fetchMessages();
      return dispatch({
        type: "handleServerSuccess",
        payload: message
      });
    }
    catch (err) {
      console.log(err);
      return dispatch({
        type: "handleServerError",
        payload: err?.response?.data.msg || err
      });
    }
  }, [initAxios, state, fetchMessages, isBackofficeUser]);

  const handleConfirmBulkMessageChange = useCallback(() => {
    toggleConfirmBulkMessageModal("");
    return sendMessage();
  }, [sendMessage]);

  const handleSubmit = async e => {
    e.preventDefault();

    // clear previous error messages
    dispatch({
      type: "handleServerError",
      payload: null
    });

    let { subject, body, audience, toRecipients, customList } = state;

    let inputErrors = validateFields({
      subject,
      body,
      ...(!audience && { toRecipients }), // only check toRecipients if audience is not set
      ...(audience && audience === "customList" && {  customList }) // check custom audience lists
    });

    if (inputErrors)
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors
      });

    if (audience) return toggleConfirmBulkMessageModal(audience);

    return sendMessage();
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    isBackofficeUser,
    state,
    isReply,
    audiences,
    toggleIsBulk,
    setAudience,
    setToRecipients,
    addFiles,
    removeFile,
    clearFiles,
    addCustomList,
    removeCustomList,
    clearCustomList,
    handleInputChange,
    handleSubmit,
    closeMessage,
    toggleConfirmBulkMessageModal,
    handleConfirmBulkMessageChange
  };
};

export default useNewMessage;
