import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Box, Typography, IconButton, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadCustomList = props => {
	const { files, addCustomList, removeCustomList, clearCustomList, error } = props;

	const handleDrop = acceptedFiles => {
		acceptedFiles.forEach(file => {
			const reader = new FileReader();

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onload = () => {
				file.rawData = reader.result;
			};
			reader.readAsDataURL(file); // Reads data as Base64 string
		});

		addCustomList(acceptedFiles);
	};

	const handleRemoveFile = (index, e) => {
		e.stopPropagation();
		removeCustomList(index);
	};

	const handleClearFiles = e => {
		e.stopPropagation();
		clearCustomList();
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleDrop,
		accept: {
			"text/plain": [".txt"]
		}
	});

	return (
		<React.Fragment>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "180px",
					border: "1px dashed #a5a5a5",
					padding: "15px",
					mb: 3,
					backgroundColor: "#f5f5f5",
					cursor: "pointer"
				}}
				{...getRootProps()}
			>
				<Fragment>
					<input {...getInputProps()} />
					{isDragActive ? (
						<Typography variant="body2" color="text.primary">
							Släpp din fil här...
						</Typography>
					) : (
						<Typography variant="body2" color="text.primary">
							Dra och släpp dina listor här eller klicka för att välja.
						</Typography>
					)}
					<Typography variant="body2" color="text.primary">
						Accepterade filtyper: .txt
					</Typography>
					{files.length <= 0 && (
						<CloudUploadIcon color="primary" sx={{ fontSize: 80, mt: 1 }} />
					)}

					<Box sx={{ mt: files.length > 0 ? 1 : 0 }}>
						{files.map((file, index) => (
							<Box
								key={index}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									backgroundColor: "#ffffff",
									border: "1px solid #a5a5a5",
									borderRadius: "4px",
									px: "10px",
									mt: 1
								}}
							>
								<Typography variant="body2">
									{file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
								</Typography>
								<IconButton
									color="secondary"
									aria-label="delete file"
									onClick={e => handleRemoveFile(index, e)}
								>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</Box>
						))}
					</Box>
					{files.length > 0 && (
						<Box sx={{ mt: 2 }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									mx: 1,
									gap: 1
								}}
							>
								<Button
									variant="outlined"
									color="primary"
									onClick={e => handleClearFiles(e)}
									startIcon={<DeleteForeverIcon />}
								>
									Radera alla
								</Button>
							</Box>
						</Box>
					)}
				</Fragment>
			</Box>

			{error && (
				<Box sx={{mt: "-10px", mb: "20px", color: "red", fontWeight: "bold"}}>
					{error}
				</Box>
			)}
		</React.Fragment>
	);
};

UploadCustomList.propTypes = {
	files: PropTypes.array,
	addCustomList: PropTypes.func,
	removeCustomList: PropTypes.func,
	clearCustomList: PropTypes.func,
	error: PropTypes.string
};

export default UploadCustomList;
