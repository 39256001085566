import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "../hooks/useErrorHandler";

import { AuthConsumer } from "./AuthProvider";
import {
  isDateBeforeYearsAgo,
  filterLastThreads,
  sortArrayByPropertyValuesOrder
} from "../helpers/functions";

const KYC_ENABLED = process.env.REACT_APP_KYC_ENABLED === "true";
const KYC_MAX_ATTEMPTS = 15; // try for 30 secs

const DashboardCtxt = createContext(null);

const DashboardProvider = ({ children }) => {
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const auth = AuthConsumer();
  const { initAxios } = useAxios();

  const [searchParams] = useSearchParams();
  const kycDoneRedirect = searchParams.get("redirect") === "fair-kyc";

  const { user, elevatedUser } = auth ? auth : null;
  const isBackofficeUser = user?.Role === "Backoffice" ? true : false;
  const isAdminUser = user?.Role === "Admin" ? true : false;
  const isCustomer = !(isAdminUser || isBackofficeUser) ? true : false;
  const isImpersonatedBy = elevatedUser ? elevatedUser.Role : false;
  const poolingInterval = useRef(0);
  const pooling_attempts = useRef(0);
  const axiosKycRequestController = useRef(null);

  let isKycRequired = false;
  if (
    KYC_ENABLED &&
    user.NoKycNeeded !== "1" && // do not show if NoKycNeeded
    user.KycCompleted && // do not show if Kyc completed date is null
    ((elevatedUser &&
      (elevatedUser.Role === "Poa" || elevatedUser.Role === "Customer")) ||
      (!elevatedUser && user.Role === "Customer")) // only show for customers and POAs
  ) {
    isKycRequired = isDateBeforeYearsAgo(user.KycCompleted, 2);
  }

  const [state, setState] = useState({
    loaded: false,
    isBackofficeUser,
    isAdminUser,
    isCustomer,
    isImpersonatedBy,
    isKycRequired
  });

  const checkKyc = useCallback(async () => {
    console.log("KYC :: Retrieving status");
    pooling_attempts.current += 1;

    if (pooling_attempts.current > KYC_MAX_ATTEMPTS) {
      // if 30 secs have passed, stop trying
      clearInterval(poolingInterval.current);
      axiosKycRequestController.current.abort();
      navigate("/");
      return window.location.reload();
    }

    try {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosKycRequestController.current = axiosController;
      const { data } = await axiosInstance.get("user/kyc");
      const kycCompletedDate = data;

      let isKycRequired = false;
      if (kycCompletedDate) {
        isKycRequired = isDateBeforeYearsAgo(kycCompletedDate, 2);
      }

      if (!isKycRequired) {
        clearInterval(poolingInterval.current);
        setState(prevState => ({
          ...prevState,
          isKycRequired
        }));
        navigate("/");
        return window.location.reload();
      }
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [initAxios, navigate, errorHandler]);

  const startPoolingKyc = useCallback(() => {
    if (poolingInterval.current) {
      clearInterval(poolingInterval.current);
    }
    poolingInterval.current = setInterval(checkKyc, 2000);
  }, [checkKyc]);

  const fetchCustomerData = useCallback(
    axios => {
      const requests = [];

      try {
        // 0. Chart data request
        const startDate = "1970-01-01";
        const endDate = "2050-01-01";

        const requestChartData = axios.get(
          `/performance/data/${startDate}/${endDate}`
        );
        requests.push(requestChartData);

        // 1. Accounts holdings data request
        const requestAllHoldings = axios.get(`/account/all/holdings/owner`);
        requests.push(requestAllHoldings);

        // 2. Accounts deposits data request
        const requestTotalDeposits = axios.get(`/account/all/deposits/owner`);
        requests.push(requestTotalDeposits);

        // 3. Accounts with Total Market Value data request
        const requestAccountsSummaryData = axios.get(
          `/account/all/summary/owner`
        );
        requests.push(requestAccountsSummaryData);

        // 4. Accounts positions and rebates
        const requestPositionsAndRebates = axios.get(
          `account/all/posrebates/owner`
        );
        requests.push(requestPositionsAndRebates);

        // 5. Get Messages
        const requestMessages = axios.get("/messages/received");
        requests.push(requestMessages);

        // 6. Get Notifications
        const requestNotifications = axios.get("/notifications");
        requests.push(requestNotifications);

        // 7. Get Advisors
        const requestAdvisors = axios.get("/user/advisors");
        requests.push(requestAdvisors);

        // 8. Get RssFeed
        const requestRssFeed = axios.get("/rssfeed");
        requests.push(requestRssFeed);

        // 9. Get First Transaction Date
        const requestFirstTransactionDate = axios.get(
          "account/all/firsttransaction/owner"
        );
        requests.push(requestFirstTransactionDate);

        Promise.all(requests)
          .then(responses => {
            const chartData = responses[0].data;
            let summaryHoldings = responses[1].data;
            let totalDeposits = responses[2].data; // Nettoinsättningar
            const accountsSummary = responses[3].data;
            const positionsAndRebates = responses[4].data; // Totala inflyttade tillgångar (pos) Total förvaltningsrabatt (rebates)
            const messages = responses[5].data;
            const notifications = responses[6].data;
            const advisors = responses[7].data;
            const rssFeed = responses[8].data;
            const firstTransactionDate = responses[9].data;

            const totalMarketValue =
              summaryHoldings.find(
                holding => holding.instrumentType === "TotalMarketValue"
              ) || {};
            // Format TotalMarketValue or if is not returned as a holding set its value to "0"
            totalMarketValue.value =
              totalMarketValue?.value?.toFixed(2).toString() || "0";

            const totalReturns = {
              instrumentType: "TotalReturns",
              instrumentTypeLabel: "Total värdeutveckling",
              value:
                Number(totalMarketValue.value) -
                (Number(totalDeposits) +
                  Number(positionsAndRebates.positions) +
                  Number(positionsAndRebates.rebates))
            };

            // sort summaryHoldings
            const sortedSummaryHoldings = sortArrayByPropertyValuesOrder(
              summaryHoldings,
              "instrumentType",
              [
                //sort order
                "Currency",
                "Share",
                "ETF",
                "Fund",
                "Warrant",
                "Bond",
                "Receivable",
                "Other"
              ]
            );

            const hideDeposit = accountsSummary.some(
              account => account.HideDeposit === "1"
            );

            setState(prevState => ({
              ...prevState,
              loaded: true,
              chartData,
              summaryHoldings: sortedSummaryHoldings,
              totalMarketValue,
              hideDeposit,
              totalDeposits, // Nettoinsättningar
              totalReturns, // Total avkastning
              accountsSummary,
              positionsAndRebates, // Totala inflyttade tillgångar (pos) Total förvaltningsrabatt (rebates)
              messages: filterLastThreads(messages),
              notifications,
              advisors,
              rssFeed,
              firstTransactionDate
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [errorHandler]
  );

  const fetchElevatedUserData = useCallback(
    axios => {
      const requests = [];

      try {
        // Accounts with Total Market Value data request
        const requestAllCustomers = axios.get("/user/customers");
        requests.push(requestAllCustomers);

        if (isAdminUser) {
          const requestAllBackofficeUsers = axios.get("/user/backoffice/list");
          requests.push(requestAllBackofficeUsers);
        }

        // Get Messages
        const requestMessages = axios.get("/messages/received");
        requests.push(requestMessages);

        // Get Notifications
        const requestNotifications = axios.get("notifications");
        requests.push(requestNotifications);

        // Get RssFeed
        const requestRssFeed = axios.get("/rssfeed");
        requests.push(requestRssFeed);

        Promise.all(requests)
          .then(responses => {
            const customers = responses[0].data;
            const backofficeUsers = isAdminUser ? responses[1].data : null;
            const messages = isAdminUser
              ? responses[2].data
              : responses[1].data;
            const notifications = isAdminUser
              ? responses[3].data
              : responses[2].data;
            const rssFeed = isAdminUser ? responses[4].data : responses[3].data;

            setState(prevState => ({
              ...prevState,
              loaded: true,
              customers,
              ...(isAdminUser && { backofficeUsers }),
              messages: filterLastThreads(messages),
              notifications,
              rssFeed
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [errorHandler, isAdminUser]
  );

  useEffect(() => {
    const { axiosInstance, axiosController } = initAxios("private");
    if (kycDoneRedirect) {
      return startPoolingKyc();
    }

    if (!state.loaded && !state.isKycRequired) {
      if (isCustomer) fetchCustomerData(axiosInstance);
      if (isBackofficeUser || isAdminUser) fetchElevatedUserData(axiosInstance);
    }

    return () => axiosController.abort();
  }, [
    kycDoneRedirect,
    startPoolingKyc,
    fetchCustomerData,
    fetchElevatedUserData,
    initAxios,
    isAdminUser,
    isBackofficeUser,
    isCustomer,
    state.loaded,
    state.isKycRequired
  ]);

  const contextValue = useMemo(
    () => ({
      state,
      setState,
      kycDoneRedirect
    }),
    [state, kycDoneRedirect]
  );

  return (
    <DashboardCtxt.Provider value={contextValue}>
      {children}
    </DashboardCtxt.Provider>
  );
};

export const DashboardConsumer = () => {
  return useContext(DashboardCtxt);
};

export default DashboardProvider;

DashboardProvider.propTypes = {
  children: PropTypes.node
};
