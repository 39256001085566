import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AuthConsumer } from "../contexts/AuthProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import useAxios from "../hooks/useAxios";

const useHomeProvider = () => {
  const navigate = useNavigate();
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const authCtxt = AuthConsumer();
  const [searchParams] = useSearchParams();
  const mobileBankIdRedirect = searchParams.get("login") === "mobileBankId";
  const { user } = authCtxt;
  const [state, setState] = useState({
    loaded: false,
    homeModal: false,
    bankIdModal: mobileBankIdRedirect,
    loginModal: false,
    rssFeed: []
  });

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const fetchRssFeed = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("default");
    axiosGlobalController.current = axiosController;

    try {
      const request = await axiosInstance.get("/rssfeed");

      setState(prevState => ({
        ...prevState,
        loaded: true,
        rssFeed: request.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  useEffect(() => {
    if (!user && !state.loaded) {
      fetchRssFeed();
    }

    return () => axiosGlobalController.current?.abort();
  }, [fetchRssFeed, state.loaded, user]);

  return {
    state,
    setState
  };
};

export default useHomeProvider;
